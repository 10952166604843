import React from "react";
import { Container } from "basis";

import { useMerchantAuth } from "../../../core/auth";
import {
  ErrorMessage,
  LoadingMessage,
  PortalBackground,
} from "../../../components";
import { MERCHANT_PAGE_ROUTES } from "../../../core/constants";

const getErrorDesc = (error) => {
  if (error.name && error.message) {
    return `${error.name}: ${error.message}`;
  }

  return error.toString();
};

const LoginCallback = () => {
  const { error, authState } = useMerchantAuth();

  const displayError = error || authState?.error;

  if (displayError) {
    return (
      <PortalBackground>
        <Container padding="6 8" hasBreakpointWidth>
          <ErrorMessage
            description={getErrorDesc(displayError)}
            additionalParams={{
              urlCancelLabel: "Back to login",
              urlCancel: MERCHANT_PAGE_ROUTES.Login,
            }}
          />
        </Container>
      </PortalBackground>
    );
  }

  return (
    <PortalBackground>
      <LoadingMessage message="Setting up your profile .." />
    </PortalBackground>
  );
};

export default LoginCallback;
